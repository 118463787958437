<template>
    <section class="view forget-password">
        <view-title v-bind:title="$t('message.forget-password.title')"></view-title>
        <div class="container py-5">
            <h5 class="text-center mb-4">Please enter a new password. </h5>
            <div class="form-row">
                <b-form-group class="col-md-12"
                              v-bind:label="$t('message.registration.password')"
                              label-for="password"
                              v-bind:invalid-feedback="invalidFeedback"
                              v-bind:valid-feedback="validFeedback">
                    <button type="button" class="btn btn-link position-absolute reveal-password"
                            v-on:click="showPassword = !showPassword">
                    <span v-if="!showPassword">
                        <font-awesome-icon v-bind:icon="['far', 'eye']"></font-awesome-icon> {{ $t("message.registration.show-password") }}
                    </span>
                        <span v-if="showPassword">
                        <font-awesome-icon v-bind:icon="['far', 'eye-slash']"></font-awesome-icon> {{ $t("message.registration.hide-password") }}
                    </span>
                    </button>
                    <b-form-input id="password"
                                  class="rounded-0"
                                  v-bind:type="showPassword ? 'text' : 'password'"
                                  v-model.trim="password">
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <button class="btn btn-block btn-dark"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="resetPassword">
                    {{ $t("message.forget-password.confirm") }}
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import {
    BFormInput,
    BFormGroup,
} from "bootstrap-vue";

import resetPassword from "../api/user/resetPassword";

import ViewTitle from "../components/ViewTitle";
export default {
    name: "ForgetPassword",
    components: {
        ViewTitle,
        BFormInput,
        BFormGroup,
    },
    data () {
        return {
            isLoading: false,
            showPassword: false,

            password: ""
        }
    },
    computed: {
        userStatus () {
            return this.$store.state.user.status;
        }
    },
    methods: {
        async resetPassword () {
            this.isLoading = true;
            try {
                await resetPassword(this.$route.query.token, this.password);
            } catch (e) {
                console.error(e);
                this.isLoading = false;
            }
            this.isLoading = false;
            this.$router.push({
                name: "login"
            });
        }
    },
    mounted () {
        if (!this.$route.query.token || this.userStatus === "loggedIn") {
            this.$router.push({
                name: "home"
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.forget-password {
    .reveal-password {
        top: 0;
        right: 0;

        &:hover {
            text-decoration: none;
        }
    }

    .btn-dark {
        max-width: 250px;
    }
}
</style>
